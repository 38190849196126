import newsletters from "../newsletters.json";
import { useParams, Link } from "react-router-dom";
import Header from "./Header";
import LandingSignUp from "./LandingSignUp";

function Newsletter() {
  const { newsletterId } = useParams();
  console.log(newsletterId);

  const newsletter = newsletters.newsletters.find(
    (newsletter) => newsletter.id.toString() === newsletterId
  );

  if (!newsletter) {
    return <div>Newsletter not found.</div>;
  }

  return (
    <div id="news-page">
      <Header></Header>
      <div id="news">
        <div id="news-body">
          <h1>{newsletter.title}</h1>
          <div id="newsletter">
            <div id="newsletter-body">
              <div id="newsletter-image">
                <img src={"../" + newsletter.image} alt="" />
              </div>
              <p>{newsletter.date}</p>
              <p>{newsletter.body}</p>
              <Link to="/news">
                <p>Back to Newsletters</p>
              </Link>
            </div>
          </div>
          <p id="news-signup">Sign up for updates on our progress</p>
          <LandingSignUp buttonText={"Submit"}></LandingSignUp>
        </div>
      </div>
      <footer id="home-footer">
        <div id="address">
          <h2>Pages:</h2>
          <div id="footer-routes">
            <Link to="/">
              <button className="header-button">Mission</button>
            </Link>
            <Link to="/ai">
              <button className="header-button">AI</button>
            </Link>
            <Link to="/demo">
              <button className="header-button">Demo</button>
            </Link>
          </div>
          <p>
          Qwytt Inc.<br></br>651 N. Broad Street Suite 201<br></br>Middletown,
            DE 19709<br></br>USA
          </p>
          <div className="footer-spaced">
            <p>contact@qwytt.ai</p>
          </div>
          {/* <div className="footer-spaced">
            <p>Cuitt™ is a trademark of Cuitt LLC.</p>
          </div> */}
        </div>
      </footer>
    </div>
  );
}

export default Newsletter;
