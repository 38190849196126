import "./App.css";
import React, { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import { AuthProvider } from "./contexts/AuthContext";
import Newsletter from "./components/Newsletter";
import ProtectedRoute from "./components/ProtectedRoute";

const Home = React.lazy(() => import("./components/pages/Home"));
const AI = React.lazy(() => import("./components/pages/AI"));
const Demo = React.lazy(() => import("./components/pages/Demo"));
const Login = React.lazy(() => import("./components/pages/Login"));
const Signup = React.lazy(() => import("./components/pages/Signup"));
const News = React.lazy(() => import("./components/pages/News"));
const Application = React.lazy(() => import("./components/pages/Application"));
const ComingSoon = React.lazy(() => import("./components/pages/ComingSoon"));

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Suspense
          fallback={
            <div
              style={{
                height: "100vh",
                width: "100vw",
                backgroundColor: "#1A1B1A",
              }}
            >
              <Header></Header>
            </div>
          }
        >
          <Routes>
            <Route path="" element={<Application/>} />
            <Route path="/ios" element={<ComingSoon/>} />
            <Route path="/android" element={<ComingSoon/>} />
            <Route path="/device" element={<Home />} />
            <Route path="/signup" element={<Signup />} />
            <Route
              path="/demo"
              element={
                // <ProtectedRoute>
                <Demo />
                // {/* </ProtectedRoute> */}
              }
            />
            <Route path="/login" element={<Login />} />
            <Route exact path="/news" element={<News />} />
            <Route path="/news/:newsletterId" element={<Newsletter />} />
          </Routes>
        </Suspense>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
