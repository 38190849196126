import "../index.css";
import React, { useState, useRef } from "react";
import { getFirestore, doc, setDoc, serverTimestamp } from "firebase/firestore";
import { app } from "../firebase-config";

function LandingSignUp({ buttonText }) {
  const [email, setEmail] = useState("");

  const db = getFirestore(app);

  const input_ref = useRef();
  const button_ref = useRef();
  const signup_ref = useRef();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (!emailRegex.test(email)) {
      console.error("Invalid email address");
      button_ref.current.innerHTML = "Invalid Email!";
      button_ref.current.backgroundColor = "#E08E8E";
      setTimeout(() => {
        button_ref.current.innerHTML = "Submit";
      }, 5000);
      return;
    }

    await setDoc(
      doc(db, "marketing_emails", "email_list"),
      {
        [email]: serverTimestamp(),
      },
      { merge: true }
    )
      .then(function () {
        input_ref.current.style.display = "none";
        button_ref.current.style.width = "100%";
        button_ref.current.style.margin = "0px";
        button_ref.current.style.backgroundColor = "#4aa87a";
        button_ref.current.innerHTML = "Thank you!";
      })
      .catch(function (error) {
        console.error("Error writing email: ", error);
      });
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  return (
    <div>
      <form>
        <div ref={signup_ref} id="landing-signup">
          <input
            ref={input_ref}
            type="email"
            placeholder="Email"
            value={email}
            onChange={handleEmailChange}
          />
          <button
            ref={button_ref}
            type="submit"
            style={{ marginLeft: "10px" }}
            onClick={handleSubmit}
          >
            {buttonText}
          </button>
        </div>
      </form>
    </div>
  );
}

export default LandingSignUp;
